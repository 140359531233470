import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const TaskPage = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({ title: '', priority: 'Low' });
  const [projectTitle, setNewProjectTitle] = useState()
  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTasks = async () => {
      if (projectId) {
        const projectRef = doc(firestore, 'projects', projectId);
        const projectSnapshot = await getDoc(projectRef);

        if (projectSnapshot.exists()) {
          const projectData = projectSnapshot.data();
          setNewProjectTitle(projectData.title)
          
        
          const sortedTasks = projectData.tasks
            ? [...projectData.tasks].sort((a, b) => {
              if (a.completed === b.completed) {
                return b.id - a.id;
              }
              return a.completed - b.completed;
            })
            : [];
          setTasks(sortedTasks);
        } else {
          console.error('Project does not exist');
        }
      }
    };

    fetchTasks();
  }, [projectId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  const handleAddTask = async (e) => {
    e.preventDefault();

    if (newTask.title.trim()) {
      const updatedTasks = [
        ...tasks,
        {
          ...newTask,
          completed: false,
          id: tasks.length + 1,
        },
      ];

      const projectRef = doc(firestore, 'projects', projectId);
      await updateDoc(projectRef, { tasks: updatedTasks });

      const sortedTasks = updatedTasks.sort((a, b) => {
        if (a.completed === b.completed) {
          return b.id - a.id;
        }
        return a.completed - b.completed;
      });

      setTasks(sortedTasks);
      setNewTask({ title: '', priority: 'Low' });
    }
  };

  const handleMarkComplete = async (taskId) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, completed: true } : task
    );

    const projectRef = doc(firestore, 'projects', projectId);
    await updateDoc(projectRef, { tasks: updatedTasks });

    const sortedTasks = updatedTasks.sort((a, b) => {
      if (a.completed === b.completed) {
        return b.id - a.id;
      }
      return a.completed - b.completed;
    });

    setTasks(sortedTasks);
  };


  const handleMarkIncomplete = async (taskId) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId ? { ...task, completed: false } : task
    );

    const projectRef = doc(firestore, 'projects', projectId);
    await updateDoc(projectRef, { tasks: updatedTasks });

    const sortedTasks = updatedTasks.sort((a, b) => {
      if (a.completed === b.completed) {
        return b.id - a.id;
      }
      return a.completed - b.completed;
    });

    setTasks(sortedTasks);
  };

  const handleBulkImportClick = () => {
    navigate(`/bulk-import/${projectId}`);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">
        <a href='/projects' className="text-blue-500 mr-2">&lt;</a>
        Tasks | {projectTitle} | <span className='text-red-600'>
          {tasks.filter(task => !task.completed).length}
          </span>
          /{tasks?.length || 0}
      </h1>

      <form onSubmit={handleAddTask} className="flex space-x-4 mb-6">
        <input
          type="text"
          name="title"
          value={newTask.title}
          onChange={handleInputChange}
          placeholder="Task Title"
          required
          className="border border-gray-300 p-2 rounded-md w-64"
        />
        <select
          name="priority"
          value={newTask.priority}
          onChange={handleInputChange}
          className="border border-gray-300 p-2 rounded-md"
        >
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
        >
          Add Task
        </button>
      </form>

      <div className="mb-6">
        <button
          onClick={handleBulkImportClick}
          className="bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
        >
          Bulk Import Tasks
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {tasks.length > 0 ? (
          tasks.map((task) => (
            <div
              key={task.id}
              className={`shadow-md p-4 rounded-lg border ${task.completed ? 'border-green-500 bg-green-50' : 'border-gray-200 bg-white'
                }`}
            >
              <h3 className="text-lg font-semibold">{task.title}</h3>
              <p><i>{task.description}</i></p>
              <div className="flex justify-around items-center text-sm text-gray-600">
                <span>Priority: {task.priority}</span>|
                <span>Completed: {task.completed ? 'Yes' : 'No'}</span>|
                <span>
                  {!task.completed ? (
                    <button
                      onClick={() => handleMarkComplete(task.id)}
                      className="text-blue-500 px-3 py-1 rounded-md hover:bg-slate-200"
                    >
                      Mark Complete
                    </button>
                  ) : (
                    <button
                      onClick={() => handleMarkIncomplete(task.id)}
                      className="text-blue-500 px-3 py-1 rounded-md hover:bg-slate-200"
                    >
                      Mark Incomplete
                    </button>
                  )}
                </span>
              </div>

            </div>
          ))
        ) : (
          <p className="text-gray-500">No tasks found for this project.</p>
        )}
      </div>
    </div>
  );
};

export default TaskPage;
