import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../firebase';
import { collection, getDocs, query, where, addDoc } from 'firebase/firestore';
import { useAuth } from '../AuthContext';

const ProjectPage = () => {
  const [projects, setProjects] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [newProjectTitle, setNewProjectTitle] = useState('');
  const [showNewProjectForm, setShowNewProjectForm] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (currentUser) {
        const userId = currentUser.uid;
        const orgCollection = collection(firestore, 'organizations');
        const orgQuery = query(orgCollection, where('owner', '==', userId));
        const orgSnapshot = await getDocs(orgQuery);
        const orgList = orgSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setOrganizations(orgList);
      }
    };

    fetchOrganizations();
  }, [currentUser]);

  useEffect(() => {
    const fetchProjects = async () => {
      if (currentUser && selectedOrg) {
        const userId = currentUser.uid;
        const projectCollection = collection(firestore, 'projects');
        const projectQuery = query(
          projectCollection,
          where('owner', '==', userId),
          where('organization', '==', selectedOrg)
        );

        const projectSnapshot = await getDocs(projectQuery);
        const projectList = projectSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProjects(projectList);
      } else {
        setProjects([]);
      }
    };

    fetchProjects();
  }, [currentUser, selectedOrg]);

  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}/tasks`);
  };

  const handleAddProjectClick = () => {
    setShowNewProjectForm(true);
  };

  const handleNewProjectSubmit = async (e) => {
    e.preventDefault();

    if (newProjectTitle.trim() && selectedOrg) {
      const userId = currentUser.uid;
      const projectCollection = collection(firestore, 'projects');

      await addDoc(projectCollection, {
        title: newProjectTitle,
        owner: userId,
        organization: selectedOrg,
        tasks: []
      });

      setNewProjectTitle('');
      setShowNewProjectForm(false);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      <div className="bg-white shadow-md p-4 border-b border-gray-300">
        <h1 className="text-2xl font-bold">Projects</h1>
      </div>

      {/* Main Content */}
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="w-1/4 bg-gray-200 p-4 border-r border-gray-300 overflow-y-auto">
          <h2 className="text-lg font-bold mb-4">Organizations</h2>
          <ul>
            {organizations.map((org) => (
              <li
                key={org.id}
                onClick={() => setSelectedOrg(org.name)}
                className={`cursor-pointer p-2 rounded-md mb-2 ${selectedOrg === org.name ? 'bg-blue-500 text-white' : 'hover:bg-gray-300'}`}
              >
                {org.name}
              </li>
            ))}
          </ul>
        </div>

        {/* Projects Section */}
        <div className="flex-1 p-4 overflow-y-auto">
          {selectedOrg && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {projects.length > 0 ? (
                projects.map((project) => (
                  <div
                    key={project.id}
                    onClick={() => handleProjectClick(project.id)}
                    className="bg-white shadow-md p-4 rounded-lg border border-gray-200 cursor-pointer hover:bg-gray-100 transition"
                  >
                    <h3 className="text-lg font-semibold">{project.title}</h3>
                    <p className="text-sm text-gray-600">
                      <span className="ml-4 text-red-600">{project.tasks.filter(task => !task.completed).length}/</span>
                      {project.tasks?.length || 0} {project.tasks?.length === 1 ? 'Task' : 'Tasks'}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No projects found for the selected organization.</p>
              )}

              {/* New Project Card */}
              <div
                onClick={handleAddProjectClick}
                className="bg-green-50 shadow-md p-4 rounded-lg border border-green-500 flex items-center justify-center cursor-pointer hover:bg-green-200 transition"
              >
                <span className="text-5xl text-gray-500">+</span>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* New Project Form Modal */}
      {showNewProjectForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
            <h2 className="text-xl font-semibold mb-4">Add New Project</h2>
            <form onSubmit={handleNewProjectSubmit} className="space-y-4">
              <input
                type="text"
                value={newProjectTitle}
                onChange={(e) => setNewProjectTitle(e.target.value)}
                placeholder="Project Title"
                required
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600 transition"
              >
                Create Project
              </button>
              <button
                type="button"
                onClick={() => setShowNewProjectForm(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-md w-full hover:bg-gray-600 transition"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectPage;
