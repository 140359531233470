import React from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';

const LayouMain = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />
      <main className="flex-grow container mx-auto p-4">{children}</main>
      <Footer />
    </div>
  );
};

export default LayouMain;
